import React, { useContext, useEffect, useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import { TagsFilter } from "../TagsFilter";
import { Button, IconButton } from "@material-ui/core";
import TicketsQueueSelect from "../TicketsQueueSelect";
import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsList";
import TabPanel from "../TabPanel";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  tabsHeader: {
    flex: "none",
    backgroundColor: "#eee",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  tab: {
    minWidth: 120,
    flex: 1,
    textAlign: "center",
  },
  selectedTab: {
    backgroundColor: "#d3d3d3",
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  tabLabel: {
    fontWeight: "bold",
  },
  searchInputWrapper: {
    width: "100%",
    background: "#eee",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 40,
    padding: 4,
    marginTop: theme.spacing(1),
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    "&:hover": {
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08)",
    },
  },
  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },
  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 30,
  },
  badge: {
    right: "-10px",
  },
  tabContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  tabRow: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  greenBadge: {
    backgroundColor: "green",
    color: "white",
  },
  redBadge: {
    backgroundColor: "red",
    color: "white",
  },
  blueBadge: {
    backgroundColor: "blue",
    color: "white",
  },
}));

const TicketsManager = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);
  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [closedCount, setClosedCount] = useState(0);
  const [assignedCount, setAssignedCount] = useState(0); // Nuevo estado para la cuenta de "POR ATENDER"
  const [selectedTags, setSelectedTags] = useState([]);

  const userQueueIds = user.queues.map((q) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);

  useEffect(() => {
    if (user.profile.toUpperCase() === "ADMIN") {
      setShowAllTickets(true);
    }
  }, [user]);

  useEffect(() => {
    if (tab === "search") {
      searchInputRef.current.focus();
    }
  }, [tab]);

  let searchTimeout;

  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();

    clearTimeout(searchTimeout);

    if (searchedTerm === "") {
      setSearchParam(searchedTerm);
      setTab("open");
      return;
    }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 500);
  };

  const handleSelectedTags = (selecteds) => {
    const tags = selecteds.map((t) => t.id);
    setSelectedTags(tags);
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const formatCount = (count) => {
    return count >= 100 ? "99+" : count;
  };

  const getFilteredTicketsList = (status) => {
    return (
      <TicketsList
        status={status}
        showAll={showAllTickets}
        selectedQueueIds={selectedQueueIds}
        searchParam={searchParam}
        updateCount={(val) => {
          if (status === "open") setOpenCount(val);
          if (status === "pending") setPendingCount(val);
          if (status === "closed") setClosedCount(val);
          if (status === "assigned") setAssignedCount(val); // Actualizar la cuenta de "POR ATENDER"
        }}
        user={user}
      />
    );
  };

  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={() => setNewTicketModalOpen(false)}
      />
      <Paper elevation={0} square className={classes.tabsHeader}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setNewTicketModalOpen(true)}
          style={{ marginRight: theme.spacing(2) }}
        >
          {i18n.t("Nuevo Whats")}
        </Button>
        <IconButton
          onClick={() => setShowSearchBar(!showSearchBar)}
          style={{ marginRight: theme.spacing(2) }}
        >
          <SearchIcon />
        </IconButton>
        <TicketsQueueSelect
          style={{ marginLeft: theme.spacing(2) }}
          selectedQueueIds={selectedQueueIds}
          userQueues={user?.queues}
          onChange={(values) => setSelectedQueueIds(values)}
        />
      </Paper>
      {showSearchBar && (
        <div className={classes.searchInputWrapper}>
          <SearchIcon className={classes.searchIcon} />
          <InputBase
            className={classes.searchInput}
            inputRef={searchInputRef}
            placeholder={i18n.t("Buscar")}
            type="search"
            onChange={handleSearch}
          />
        </div>
      )}
      <div className={classes.tabContainer}>
        {user.profile.toUpperCase() === "ADMIN" ? (
          <>
            <div className={classes.tabRow}>
              <Tab
                value={"pending"}
                label={
                  <Badge badgeContent={formatCount(pendingCount)} classes={{ badge: classes.redBadge }}>
                    <span className={classes.tabLabel}>{i18n.t("POR ASIGNAR")}</span>
                  </Badge>
                }
                classes={{ root: classes.tab, selected: classes.selectedTab }}
                onClick={() => handleChangeTab(null, "pending")}
              />
              <Tab
                value={"assigned"}
                label={
                  <Badge badgeContent={formatCount(assignedCount)} classes={{ badge: classes.redBadge }}>
                    <span className={classes.tabLabel}>{i18n.t("POR ATENDER")}</span>
                  </Badge>
                }
                classes={{ root: classes.tab, selected: classes.selectedTab }}
                onClick={() => handleChangeTab(null, "assigned")}
              />
            </div>
            <div className={classes.tabRow}>
              <Tab
                value={"open"}
                label={
                  <Badge badgeContent={formatCount(openCount)} classes={{ badge: classes.blueBadge }}>
                    <span className={classes.tabLabel}>{i18n.t("EN GESTIÓN")}</span>
                  </Badge>
                }
                classes={{ root: classes.tab, selected: classes.selectedTab }}
                onClick={() => handleChangeTab(null, "open")}
              />
              <Tab
                value={"closed"}
                label={
                  <Badge badgeContent={formatCount(closedCount)} classes={{ badge: classes.greenBadge }}>
                    <span className={classes.tabLabel}>{i18n.t("FINALIZADOS")}</span>
                  </Badge>
                }
                classes={{ root: classes.tab, selected: classes.selectedTab }}
                onClick={() => handleChangeTab(null, "closed")}
              />
            </div>
          </>
        ) : (
          <div className={classes.tabRow}>
            <Tab
              value={"assigned"}
              label={
                <Badge badgeContent={formatCount(assignedCount)} classes={{ badge: classes.redBadge }}>
                  <span className={classes.tabLabel}>{i18n.t("POR ATENDER")}</span>
                </Badge>
              }
              classes={{ root: classes.tab, selected: classes.selectedTab }}
              onClick={() => handleChangeTab(null, "assigned")}
            />
            <Tab
              value={"open"}
              label={
                <Badge badgeContent={formatCount(openCount)} classes={{ badge: classes.blueBadge }}>
                  <span className={classes.tabLabel}>{i18n.t("EN GESTIÓN")}</span>
                </Badge>
              }
              classes={{ root: classes.tab, selected: classes.selectedTab }}
              onClick={() => handleChangeTab(null, "open")}
            />
            <Tab
              value={"closed"}
              label={
                <Badge badgeContent={formatCount(closedCount)} classes={{ badge: classes.greenBadge }}>
                  <span className={classes.tabLabel}>{i18n.t("FINALIZADOS")}</span>
                </Badge>
              }
              classes={{ root: classes.tab, selected: classes.selectedTab }}
              onClick={() => handleChangeTab(null, "closed")}
            />
          </div>
        )}
      </div>
      {user.profile.toUpperCase() === "ADMIN" && (
        <TabPanel value={tab} name="pending" className={classes.ticketsWrapper}>
          {getFilteredTicketsList("pending")}
        </TabPanel>
      )}
      <TabPanel value={tab} name="assigned" className={classes.ticketsWrapper}>
        {getFilteredTicketsList("assigned")}
      </TabPanel>
      <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
        {getFilteredTicketsList("open")}
      </TabPanel>
      <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
        {getFilteredTicketsList("closed")}
      </TabPanel>
      <TabPanel value={tab} name="search" className={classes.ticketsWrapper}>
        <TagsFilter onFiltered={handleSelectedTags} />
        <TicketsList
          searchParam={searchParam}
          tags={selectedTags}
          showAll={showAllTickets}
          selectedQueueIds={selectedQueueIds}
          user={user}
        />
      </TabPanel>
    </Paper>
  );
};

export default TicketsManager;
