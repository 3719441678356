import openSocket from "socket.io-client";
import { getBackendUrl } from "../config";

function connectToSocket() {
  const token = localStorage.getItem("token");
  const socket = openSocket(getBackendUrl(), {
    query: {
      auth_token: token, // Pasar el token como una cadena
    },
  });

  return socket;
}

export default connectToSocket;
