import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";

const useStyles = makeStyles((theme) => ({
  maxWidth: {
    width: "100%",
  },
}));

const DictaminarTicketModal = ({ modalOpen, onClose }) => {
	const [loading] = useState(false);
	const [selectedQueue, setSelectedQueue] = useState('');
	const classes = useStyles();

	const handleClose = () => {
		onClose();
	};

	return (
		<Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
			<form>
				<DialogTitle id="form-dialog-title">
					{i18n.t("dictamenTicketModal.title")}
				</DialogTitle>
				<DialogContent dividers>
					<FormControl variant="outlined" className={classes.maxWidth}>
						<TextField
							style={{ marginBottom: 20 }}
							label={i18n.t("dictamenTicketModal.creditoLabel")}
							variant="outlined"
							required
							autoFocus
						/>
					</FormControl>
					<FormControl variant="outlined" className={classes.maxWidth}>
						<TextField
							style={{ marginBottom: 20 }}
							label={i18n.t("dictamenTicketModal.telefonoLabel")}
							variant="outlined"
							required
						/>
					</FormControl>
					<FormControl variant="outlined" className={classes.maxWidth}>
						<InputLabel>{i18n.t("dictamenTicketModal.dictamenLabel")}</InputLabel>
						<Select
							style={{ marginBottom: 20 }}
							label={i18n.t("dictamenTicketModal.dictamenLabel")}
						>
							<MenuItem value={'Promesa de pago'}>Promesa de pago.</MenuItem>
							<MenuItem value={'En proceso de liquidación'}>En proceso de liquidación.</MenuItem>
							<MenuItem value={'Recordatorio de pago'}>Recordatorio de pago.</MenuItem>
							<MenuItem value={'Intento de recordatorio'}>Intento de recordatorio.</MenuItem>
							<MenuItem value={'Confirma pago con ticket'}>Confirma pago con ticket.</MenuItem>
							<MenuItem value={'Confirma pago sin ticket'}>Confirma pago sin ticket.</MenuItem>
							<MenuItem value={'Negativa'}>Negativa.</MenuItem>
							<MenuItem value={'Aclaración'}>Aclaración.</MenuItem>
							<MenuItem value={'Recado'}>Recado.</MenuItem>
							<MenuItem value={'Equivocado'}>Equivocado.</MenuItem>
							<MenuItem value={'No Contestan'}>No contestan.</MenuItem>
						</Select>
					</FormControl>
					<FormControl variant="outlined" className={classes.maxWidth}>
						<TextField
							style={{ marginBottom: 20 }}
							label={i18n.t("dictamenTicketModal.comentarioLabel")}
							variant="outlined"
							required
						/>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("dictamenTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="submit"
						color="primary"
						loading={loading}
					>
						{i18n.t("dictamenTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default DictaminarTicketModal;
