import React, { useContext } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../context/Auth/AuthContext"; // Asegúrate de que el path sea correcto
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    padding: theme.spacing(2),
  },
  list: {
    maxHeight: 180, // Ajusta la altura máxima de acuerdo a tus necesidades
    overflowY: 'auto',
  }
}));

const ConnectedAgents = () => {
  const classes = useStyles();
  const { connectedUsers } = useContext(AuthContext);

  return (
    <Paper>
      <Typography component="h2" variant="h6" color="primary" className={classes.title}>
        Agentes Conectados
      </Typography>
      <List className={classes.list}>
        {connectedUsers.map(user => (
          <ListItem key={user.socketId} className={classes.listItem}>
            <ListItemAvatar>
              <Avatar>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={user.name} />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default ConnectedAgents;
