import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import connectToSocket from "../../services/socket-io";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useAuth = () => {
  const history = useHistory();
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [connectedUsers, setConnectedUsers] = useState([]);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      (async () => {
        try {
          const { data } = await api.post("/auth/refresh_token");
          api.defaults.headers.Authorization = `Bearer ${data.token}`;
          setIsAuth(true);
          setUser(data.user);
          localStorage.setItem("token", data.token); // Actualizar el token en localStorage
          const newSocket = connectToSocket();
          setSocket(newSocket);
          newSocket.emit("userConnected", data.user.id);
          newSocket.on("updateUserList", setConnectedUsers);
        } catch (err) {
          toastError(err);
        } finally {
          setLoading(false);
        }
      })();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("user", data => {
        if (data.action === "update" && data.user.id === user.id) {
          setUser(data.user);
        }
      });
      socket.on("updateUserList", setConnectedUsers);
      return () => {
        socket.disconnect();
      };
    }
  }, [socket, user]);

  const handleLogin = async userData => {
    setLoading(true);
    try {
      const { data } = await api.post("/auth/login", userData);
      localStorage.setItem("token", data.token); // Almacenar token como cadena
      api.defaults.headers.Authorization = `Bearer ${data.token}`;
      setUser(data.user);
      setIsAuth(true);
      const newSocket = connectToSocket();
      setSocket(newSocket);
      newSocket.emit("userConnected", data.user.id);
      toast.success(i18n.t("auth.toasts.success"));
      history.push("/tickets");
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    setLoading(true);
    try {
      if (socket) {
        socket.emit("userDisconnected", user.id);
        socket.disconnect();
      }
      await api.delete("/auth/logout");
      setIsAuth(false);
      setUser({});
      localStorage.removeItem("token");
      api.defaults.headers.Authorization = undefined;
      history.push("/login");
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  return { isAuth, user, loading, handleLogin, handleLogout, connectedUsers };
};

export default useAuth;
